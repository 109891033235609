<template>
  <div class="examRule p-t-30">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="670px"
    >
      <div class="examRule_head flex ">
        <span>房源介绍</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="p-l-30 p-r-30 p-t-20 p-b-20 view-pop">
        <div class="suggest-pop">
          <div class="t">小区简介</div>
          <div class="c m-t-10 m-b-15">{{ content.xiaoqu_content }}</div>
          <div class="t">综合介绍</div>
          <div class="c m-t-10 m-b-15">{{ content.zong_content }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible:false,
      content:{},//
    }
  },
  methods:{
    //关闭
    close () {
      this.dialogVisible = false
    },
    //打开
    open (id) {
      this.$axios.xiaoQuDetail({id}).then(res=>{
        this.dialogVisible = true
        this.content = res.data
      })
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header ,/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog{border-radius:8px;overflow:hidden}

.f-24{font-size:24px;}
.view-pop{
  .view-text{background:#F5F5F5;border-radius:8px;padding:10px;}
  /deep/ .view-text .el-textarea__inner{border:none;outline:none;background:none;font-size:18px;}
}
.suggest-pop{
  .t{fnt-size:18px;font-weight:600;}
  .c{font-size:16px;color:#666;line-height:28px;}
}
.interview_main_footer {
  padding: 40px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
