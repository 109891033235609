<template>
  <div class="examRule">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="960px"
    >
      <div class="examRule_head flex">
        <span>{{ details.name }}</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="p-l-30 p-r-20 p-t-20 p-b-20 view-pop">
        <div class="more-pop">
          <div class="more-box">
            <div class="tit m-b-10">基础信息</div>
            <ul class="more-ul">
              <li class="w48">
                <div class="label">小区名称：</div>
                <div class="c">{{ details.name }}</div>
              </li>
              <li class="w48">
                <div class="label">物业类型：</div>
                <div class="c">{{ getHouseType(details.wuye_lei) }}</div>
              </li>
              <li class="w48">
                <div class="label">权属类别：</div>
                <div class="c">{{ details.quanshu_lei }}</div>
              </li>
              <li class="w48">
                <div class="label">竣工时间：</div>
                <div class="c">{{ details.jun_time }}</div>
              </li>
              <li class="w48">
                <div class="label">产权年限：</div>
                <div class="c">{{ details.chanquan_nian }}</div>
              </li>
              <li class="w48">
                <div class="label">开发商：</div>
                <div class="c">{{ details.developers }}</div>
              </li>
              <li class="w48">
                <div class="label">总户数：</div>
                <div class="c">{{ details.hus }}</div>
              </li>
              <li class="w48">
                <div class="label">总建面积：</div>
                <div class="c">{{ details.mianji ? details.mianji.join('-') : '' }}㎡</div>
              </li>
              <li class="w48">
                <div class="label">绿化率：</div>
                <div class="c">{{ details.greening_rate }}</div>
              </li>
              <li class="w48">
                <div class="label">容积率：</div>
                <div class="c">{{ details.plot_ratio }}</div>
              </li>
              <!-- <li class="w48">
                <div class="label">建筑类型：</div>
                <div class="c">{{ details.jian_lei }}</div>
              </li> -->
              <li class="w48">
                <div class="label">小区地址：</div>
                <div class="c">{{ details.address }}</div>
              </li>
              <li class="w48">
                <div class="label">所属商圈：</div>
                <div class="c">{{ details.shangquan }}</div>
              </li>
            </ul>
          </div>
          <div class="more-box">
            <div class="tit m-b-10">物业信息</div>
            <ul class="more-ul">
              <li class="w100">
                <div class="label">统一供暖：</div>
                <div class="c">{{ details.gongnuan }}</div>
              </li>
              <li class="w48">
                <div class="label">暖气费：</div>
                <div class="c">{{ details.nuanqi_price }}</div>
              </li>
              <li class="w48">
                <div class="label">供水：</div>
                <div class="c">{{ details.gongshui }}</div>
              </li>
              <li class="w48">
                <div class="label">水费：</div>
                <div class="c">{{ details.shui_price }}</div>
              </li>
              <li class="w48">
                <div class="label">用电：</div>
                <div class="c">{{ details.dianfei }}</div>
              </li>
              <li class="w48">
                <div class="label">电费：</div>
                <div class="c">{{ details.dian_price }}</div>
              </li>
              <li class="w48">
                <div class="label">停车位：</div>
                <div class="c">{{ details.chewei_shu }}</div>
              </li>
              <li class="w48">
                <div class="label">车位配比：</div>
                <div class="c">{{ details.che_bi }}</div>
              </li>
              <li class="w48">
                <div class="label">停车费：</div>
                <div class="c">{{ details.che_price }}</div>
              </li>
              <li class="w48">
                <div class="label">车位管理费：</div>
                <div class="c">{{ details.che_guan_price }}</div>
              </li>
              
              <li class="w48">
                <div class="label">物业公司：</div>
                <div class="c">{{ details.wuye }}</div>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id:null,//
      type:1,//1出售2出租
      details:{},//
      dialogVisible: false
    };
  },
  methods: {
    //关闭
    close() {
      this.dialogVisible = false;
    },
    getDetails () {
      this.$axios.xiaoQuDetail({ id: this.id }).then((res) => {
        this.details = res.data;
        this.dialogVisible = true;
      });
    },
    //打开
    open(id) {
      this.id = id
      this.getDetails();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

// .examRule_main {
//   // line-height: 28px;
// }
.f-24 {
  font-size: 24px;
}
.view-pop {
  .view-text {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
  }
  /deep/ .view-text .el-textarea__inner {
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
  }
}
.more-pop {
  .more-box {
    padding: 15px 0px;
  }
  .tit {
    font-size: 18px;
  }
  .more-ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .more-ul li {
    margin: 5px 0px;
    display: flex;
    font-size: 16px;
    line-height: 24px;
  }
  .more-ul li .c {
    line-height: 1.6;
  }
  .w48 {
    width: 48%;
  }
  .w100 {
    width: 100%;
  }
  .more-pop-img {
    width: 116px;
    height: 116px;
    border-radius: 4px;
  }
  .more-ul li .label {
    color: #999;
    white-space: nowrap;
    line-height: 1.6;
  }
  .more-shadow {
    box-shadow: 0px 0px 6px 0px rgba(189, 189, 189, 0.16);
    border-radius: 4px;
    padding: 20px;
    display: flex;
    display: flex;
    .shadow-img {
      width: 120px;
      height: 90px;
      border: 2px solid #ebebeb;
    }
    .txt {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .t {
        font-size: 20px;
        line-height: 1.6;
      }
      .c {
        font-size: 18px;
        color: #666;
        line-height: 1.6;
      }
    }
  }
}
.interview_main_footer {
  padding: 40px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
