<template>
  <div class="examRule">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="462px"
    >
      <div class="examRule_head flex ">
        <span>梯户比例</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="p-l-20 p-r-20 p-t-40 p-b-40 view-pop">
        <div class=" color-3 m-t-20 m-b-40"><span class="color-9">梯户比例：</span> 
        <template v-for="(item) in ladderList">{{ item }}、</template>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible:false,
      ladderList:[],//梯户
    }
  },
  methods:{
    //关闭
    close () {
      this.dialogVisible = false
    },
    //打开
    open (list) {
      let ladderList = []
        if (list.length && list[0]) {
          list.forEach(element => {
            let txt = element.split('/')[0] + '梯' + element.split('/')[1] + '户' + element.split('/')[2] + '%'
            ladderList.push(txt)
          });
          this.ladderList = ladderList
        }
       this.dialogVisible = true
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header ,/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog{border-radius:8px;overflow:hidden}

// .examRule_main {
//   // line-height: 28px;
// }
.f-24{font-size:24px;}
.view-pop{
  .view-text{background:#F5F5F5;border-radius:8px;padding:10px;}
  /deep/ .view-text .el-textarea__inner{border:none;outline:none;background:none;font-size:18px;}
}
.interview_main_footer {
  padding: 40px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
