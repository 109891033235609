// 房源详情
<template>
  <div class="housing b-c-f">
    <div class="housing_box w-1200 margin-auto">
      <div class="housing_nav p-t-30 p-b-30">
        <div class="housing_nav_top flex m-b-40">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/housing?type=1' }"
              >房源管理</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/estate?type=1' }"
              >小区</el-breadcrumb-item
            >
            <el-breadcrumb-item class="color_blue">详情</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="housing_nav_time">上架时间：{{ details.createtime }}</div>
        </div>
        <div class="housing_nav_main flex">
          <div class="housing_nav_main_left flexs">
            <div class="housing_nav_name m-r-15">{{ details.name }}</div>
          </div>
          <div class="housing_nav_main_right flexs">
            <div class="housing_nav_number">编号：{{ details.id }}</div>
          </div>
        </div>
      </div>
      <div class="housing_head flex">
        <div class="housing_head_left">
          <div class="housing_head_left_video">
            <swiper class="swiper" ref="mySwiper1" :options="swiper">
              <swiper-slide
                v-for="(item, index) in details.lunbo_images"
                :key="index"
              >
                <img :src="item" alt="" />
              </swiper-slide>
              <swiper-slide v-for="item in details.vediofile" :key="item">
                <video controls :src="item"></video>
              </swiper-slide>
              <div class="swiper-paginations" slot="pagination"></div>
              <div
                class="swiper-button-prev"
                @click="prev('mySwiper1')"
                slot="button-prev"
              ></div>
              <div
                class="swiper-button-next"
                @click="next('mySwiper1')"
                slot="button-next"
              ></div>
            </swiper>
          </div>
          <div class="housing_head_left_swiper m-t-10 m-b-20">
            <swiper
              class="swiper swipers"
              ref="mySwiper"
              :options="swiperOption"
            >
              <swiper-slide
                v-for="(item, index) in details.lunbo_images"
                :key="index"
              >
                <!-- <swiper-slide v-for="(item, index) in 10" :key="index"> -->
                <!-- <img :src="item" alt="" /> -->
                <el-image
                  style="width: 100%; height: 100%"
                  :src="item"
                  :preview-src-list="details.lunbo_images"
                >
                </el-image>
                <!-- <div class="slide-icon">
                  <img src="../../assets/image/houseing/plays.png" />
                </div> -->
              </swiper-slide>
              <!-- <div class="swiper-pagination" slot="pagination"></div> -->
              <div
                class="swiper-button-prev"
                slot="button-prev"
                @click="prev('mySwiper')"
              ></div>
              <div
                class="swiper-button-next"
                slot="button-next"
                @click="next('mySwiper')"
              ></div>
            </swiper>
          </div>
        </div>
        <div class="housing_head_right">
          <!-- <div class="housing_head_price flexs">
            <div class="housing_head_price_txt f-1 flexs">
              <span>售卖均价</span>
              <span>{{ details.junjia_price }}元/m²</span>
            </div>
            <div class="housing_head_price_txt f-1 flexs">
              <span>售卖区间价</span>
              <span
                >{{
                  details.qujian_price && details.qujian_price.length
                    ? details.qujian_price[0]
                    : 0
                }}-{{
                  details.qujian_price && details.qujian_price.length
                    ? details.qujian_price[1]
                    : 0
                }}元/m²</span
              >
            </div>
          </div> -->
          <div class="housing_head_price flexs">
            <div class="housing_head_price_txt f-1 flexs">
              <span>租赁均价</span>
              <span>{{ details.zu_price }}元/月</span>
            </div>
            <div class="housing_head_price_txt f-1 flexs">
              <span>租赁区间价</span>
              <span
                >{{
                  details.zu_qujian_price && details.zu_qujian_price.length
                    ? details.zu_qujian_price[0]
                    : 0
                }}-{{
                  details.zu_qujian_price && details.zu_qujian_price.length
                    ? details.zu_qujian_price[1]
                    : 0
                }}元/月</span
              >
            </div>
          </div>
          <div class="housing_head_price flexs m-t-20">
            <div class="housing_head_price_txt f-1 flexs">
              <span>面积区间</span>
              <span>{{
                  details.mianji && details.mianji.length
                    ? details.mianji[0]
                    : 0
                }}-{{
                  details.mianji && details.mianji.length
                    ? details.mianji[1]
                    : 0
                }}㎡</span>
            </div>
          </div>
          <ul class="housing_head_tag flexs flex-wrap m-t-15">
            <li
              v-for="(item, index) in details.spans"
              :key="index"
              class="center"
            >
              {{ item }}
            </li>
          </ul>
          <ul class="housing_head_area flex m-t-15 m-b-15">
            <!-- <li>
              <span>{{ details.zaishou_number }}套</span>
              <span>在售套数</span>
            </li> -->
            <li>
              <span>{{ details.zu_number }}套</span>
              <span>在租套数</span>
            </li>
          </ul>

          <ul
            class="housing_head_footer housing_head_time flexs flex-wrap m-t-30"
          >
            <li>
              <span>总户数：</span>
              <span>{{ details.hus }}</span>
            </li>
            <li>
              <span>小区物业：</span>
              <span>{{ details.wuye }}</span>
            </li>
            <!-- <li>
              <span>面积介绍：</span>
              <span
                >{{
                  details.mianji && details.mianji.length
                    ? details.mianji[0]
                    : 0
                }}-{{
                  details.mianji && details.mianji.length
                    ? details.mianji[1]
                    : 0
                }}㎡</span
              >
            </li> -->
            <li>
              <span>车位配比：</span>
              <span>{{ details.che_bi }}</span>
            </li>
            <li class="flex">
              <div class="flexs">
                <span>梯户比例：</span>
                <span v-if="ladderList.length">{{ ladderList[0] || "" }}</span>
              </div>
              <img
                @click="$refs.lift.open(details.ti)"
                class="house-more-icon"
                src="../../assets/image/houseing/house56.png"
              />
            </li>
            <li>
              <span>绿化率：</span>
              <span>{{ details.greening_rate }}</span>
            </li>
            <li>
              <span>户型区间：</span>
              <span>{{ details.hu_qujian }}</span>
            </li>
            <li>
              <span>竣工时间：</span>
              <span>{{ details.jun_time }}</span>
            </li>
            <li>
              <span>有无电梯：</span>
              <span>{{ details.dianti }}</span>
            </li>
            <li>
              <span>开发商名称：</span>
              <span>{{ details.developers }}</span>
            </li>
            <li class="flex">
              <div class="flex-1">
                <span>地址：</span>
                <span>{{ details.address }}</span>
              </div>
              <img
                class="house-more-icon"
                @click="$refs.more.open(id)"
                src="../../assets/image/houseing/house56.png"
              />
            </li>
          </ul>
          <router-link
            class="house-last-item m-b-20 flex-between"
            :to="`/estateTotal?id=${id}&type=1`"
          >
            <div class="flexs">
              <img
                class="house-last-img m-r-10"
                src="../../assets/image/houseing/house54.png"
              />
              <span class="t">小区统计</span>
            </div>
            <img
              class="house-last-icon"
              src="../../assets/image/houseing/house56.png"
            />
          </router-link>
          <router-link
            class="house-last-item m-b-20 flex-between"
            :to="`/estateEdit?id=${id}&type=1`"
          >
            <div class="flexs">
              <img
                class="house-last-img m-r-10"
                src="../../assets/image/houseing/house55.png"
              />
              <span class="t">编辑</span>
            </div>
            <img
              class="house-last-icon"
              src="../../assets/image/houseing/house56.png"
            />
          </router-link>
        </div>
      </div>
      <!--housing-detail start-->
      <div class="housing-detail p-b-40">
       
        <!--小区介绍 detail-estate start-->
        <div class="detail-estate p-t-30 p-b-30">
          <div class="detail-tit">小区介绍</div>
          <div class="est-con m-t-30">
            <img class="est-img m-r-30" :src="details.fengmian_image" />
            <div class="est-txt">
              <div class="t">小区简介</div>
              <div class="c m-t-10 m-b-15">{{ details.xiaoqu_content }}</div>
              <div class="t">综合介绍</div>
              <div class="c m-t-10 m-b-15">{{ details.zong_content }}</div>
              <div class="detail-more" @click="$refs.suggest.open(id)">
                更多信息
              </div>
            </div>
          </div>
        </div>
        <!--小区介绍 detail-estate end-->
        <!--户型介绍 detail-introduce start-->
        <div class="detail-introduce m-b-30">
          <div class="detail-tit">户型介绍</div>
          <ul class="introduce-ul m-t-20">
            <li v-for="(item, index) in details.huxing_id" :key="index">
              <div class="li-con">
                <div class="li-img">
                  <img :src="item.image" />
                </div>
                <div class="li-txt">
                  <div class="li-c">{{ item.huxing }}</div>
                  <div class="li-c">
                    面积{{ item.mianji }}m²/{{ item.chaoxiang }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!--户型介绍 detail-introduce end-->
         <!--周边配套 detail-matching start-->
         <div class="detail-matching">
          <div class="detail-tit">周边配套</div>
          <div class="detail-map m-t-30">
            <div class="detail-mating">
              <div id="container"></div>
              <div class="detail-mating-box">
                <div class="detail-mating-main">
                  <div class="detail-mating-head flexs">
                    <div
                      @click="changeMating(index)"
                      :class="{
                        active: matingHead[matingIndex].type == item.type,
                      }"
                      class="detail-mating-head-li"
                      v-for="(item, index) in matingHead"
                      :key="index"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                  <ul class="detail-mating-list">
                    <li v-for="(item, index) in matingList" :key="index">
                      <div class="detail-mating-list-top flex">
                        <span>{{ item.name }}</span>
                        <span>{{ item.detail_info.distance }}m</span>
                      </div>
                      <div class="detail-mating-list-lu">
                        {{ item.address }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--周边配套 detail-matching end-->
      </div>
      <!--housing-detail end-->
    </div>

    <!--梯户比例弹出 start-->
    <build-lift ref="lift"></build-lift>
    <!--梯户比例弹出 end-->

    <!--小区介绍弹出 start-->
    <build-suggest ref="suggest"></build-suggest>
    <!--小区介绍弹出 end-->
    <!--  -->
    <build-more-estate ref="more"></build-more-estate>
  </div>
</template>

<script>
import BuildLift from "../../components/BuildLift";
import BuildSuggest from "../../components/BuildSuggest";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import BuildMoreEstate from '../../components/BuildMoreEstate.vue';
// import 'swiper/css/swiper.css'
export default {
  components: {
    Swiper,
    SwiperSlide,
    BuildLift,
    BuildSuggest,
    BuildMoreEstate
  },
  data() {
    return {
      id: null, //房源ID
      details: {}, //详情
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 5,
        slidesPerGroup: 4,
        // loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      center: {
        lng: "116.404",
        lat: "39.915",
      },
      zoom: 16,
      swiper: {
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      ladderList: [], //梯户
      matingIndex: 0, //
      isMating: false, //
      matingHead: [
        {
          name: "交通",
          type: "公交",
        },
        {
          name: "教育",
          type: "学校",
        },
        {
          name: "医疗",
          type: "医院",
        },
        {
          name: "生活",
          type: "生活",
        },
        {
          name: "休闲",
          type: "休闲",
        },
      ],
      matingList: [], //配套
    };
  },
  methods: {
    //跳转详情页面
    hrefEdit() {
      this.$router.push({ path: "/AddHousing" });
    },
    prev(swiper) {
      this.$refs[swiper].$swiper.slidePrev();
    },
    next(swiper) {
      this.$refs[swiper].$swiper.slideNext();
    },
    //查看联系方式
    lookPhone() {
      this.$axios
        .fangLouLook({
          id: this.id,
        })
        .then((res) => {
          console.log(res);
        });
    },
    //是否加入黑名单
    changeHei(index) {
      if (index == 2) {
        this.$axios
          .fangHei({
            id: this.id,
            fang_type: 1, //1=出售2=出租
          })
          .then((res) => {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.getDetails();
          });
      }
    },
    //是否关注
    changeColl() {
      this.$axios
        .fangGuanZhu({
          id: this.id,
        })
        .then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getDetails();
        });
    },
    //获取详情
    getDetails() {
      this.$axios.xiaoQuDetail({ id: this.id }).then((res) => {
        this.details = res.data;
        let ladderList = [];
        if (res.data.ti.length && res.data.ti[0]) {
          res.data.ti.forEach((element) => {
            let txt =
              element.split("/")[0] +
              "梯" +
              element.split("/")[1] +
              "户" +
              element.split("/")[2] +
              "%";
            ladderList.push(txt);
          });
          this.ladderList = ladderList;
        }
        this.center.lng = res.data.log;
        this.center.lat = res.data.lat;
        var center = new TMap.LatLng(res.data.lat, res.data.log);
        var map = new TMap.Map("container", {
          rotation: 20, //设置地图旋转角度
          pitch: 30, //设置俯仰角度（0~45）
          zoom: 15, //设置地图缩放级别
          center: center, //设置地图中心点坐标
        });
        var marker = new TMap.MultiMarker({
          map: map,
          styles: {
            // 点标记样式
            marker: new TMap.MarkerStyle({
              width: 20, // 样式宽
              height: 30, // 样式高
              anchor: { x: 10, y: 30 }, // 描点位置
            }),
          },
          geometries: [
            // 点标记数据数组
            {
              // 标记位置(纬度，经度，高度)
              position: center,
              id: "marker",
            },
          ],
        });
        this.getMap();
      });
    },
    //点击标注点
    infoWindowOpen() {
      this.isMating = !this.isMating;
    },
    //切换
    changeMating(index) {
      this.matingIndex = index;
      this.getMap();
    },
    getMap() {
      this.$axios
        .map1({
          bank: this.matingHead[this.matingIndex].type,
          location: this.details.lat + "," + this.details.log,
        })
        .then((res) => {
          this.matingList = res.data.results;
        });
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetails();
  },
};
</script>

<style lang="less" scoped>
.house-last-item {
  display: flex;
  align-items: center;
  background: #e1ebff;
  padding: 0 15px;
  height: 64px;
  border-radius: 4px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  .house-last-img {
    width: 36px;
    height: 36px;
  }
  .house-last-icon {
    width: 10px;
    height: 18px;
  }
}

.house-more-icon {
  width: 7px;
  height: 13px;
  cursor: pointer;
}

.swipers {
  .swiper-button-next {
    background: #171515 url("../../assets/image/houseing/house38.png") no-repeat
      center;
    width: 30px;
    height: 100px;
    right: 0px;
    top: 0;
    margin: 0;
  }
  .swiper-button-next:after {
    display: none;
  }
  .swiper-button-prev {
    background: #171515 url("../../assets/image/houseing/house37.png") no-repeat
      center;
    width: 30px;
    height: 100px;
    left: 0px;
    top: 0;
    margin: 0;
  }
  .swiper-button-prev:after {
    display: none;
  }
}

.slide-icon {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slide-icon img {
  width: 28px;
  height: 28px;
}

.housing-detail {
  .detail-tit {
    font-size: 22px;
    font-weight: 600;
  }
  .detail-more {
    width: 420px;
    height: 56px;
    background: #f5f5f5;
    cursor: pointer;
    font-size: 20px;
    color: #89b0ff;
    display: block;
    text-align: center;
    line-height: 56px;
    margin: 25px auto;
  }

  .detail-estate {
    .est-con {
      display: flex;
      justify-content: space-between;
    }
    .est-img {
      width: 560px;
      height: 420px;
      border-radius: 4px;
    }
    .t {
      font-size: 18px;
      font-weight: 600;
      position: relative;
      padding-left: 10px;
    }
    .t:before {
      width: 4px;
      height: 18px;
      background: #3273f6;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -9px;
      content: "";
    }
    .c {
      line-height: 28px;
      color: #666;
      font-size: 16px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }
  }

  .detail-look {
    .detail-look-btn {
      width: 120px;
      height: 40px;
      background: #d8e5ff;
      border-radius: 4px;
      font-size: 18px;
      color: #3273f6;
      border: none;
      outline: none;
    }
    .look-item {
      padding: 10px 0px;
      .hd {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .look-img {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 100%;
      }
      .txt {
        margin-left: 10px;
      }
      .name {
        font-size: 16px;
      }
      .date {
        font-size: 12px;
        color: #999;
      }
      .look-icon {
        width: 36px;
        height: 36px;
      }
      .article {
        font-size: 14px;
        color: #666;
        line-height: 20px;
      }
    }
  }
  .detail-introduce {
    .introduce-ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 33.3333%;
      }
      .li-con {
        margin: 10px 10px 10px 10px;
      }
      .li-img {
        width: 360px;
        height: 270px;
        border: 1px solid #ebebeb;
      }
      .li-img img {
        display: block;
        width: 100%;
      }
      .li-txt {
        margin: 10px 0px 0 0;
      }
      .li-c {
        font-size: 18px;
        color: #666;
        line-height: 1.6;
      }
    }
  }
  .detail-matching {
    .detail-map {
      border-radius: 4px;
      height: 580px;
      .detail-mating {
        height: 540px;
        position: relative;
        .bm-view {
          width: 100%;
          height: 100%;
        }
        .detail-mating-box {
          width: 440px;
          height: 100%;
          top: 20px;
          position: absolute;
          bottom: 20px;
          right: 20px;
          padding: 0 20px;
          z-index: 22;
          .detail-mating-main {
            padding: 0 20px;
            background: #ffffff;
            border-radius: 4px;
            .detail-mating-head {
              .detail-mating-head-li {
                height: 60px;
                cursor: pointer;
                margin-right: 40px;
                line-height: 60px;
                color: #333333;
                font-size: 18px;
                &:last-child {
                  margin-right: 0;
                }
              }
              .active {
                color: #3273f6;
                font-size: 18px;
                font-weight: bold;
              }
            }
            .detail-mating-list {
              li {
                padding: 20px 0;
                border-top: 1px solid #ebebeb;
                .detail-mating-list-top {
                  span {
                    color: #333333;
                    font-size: 16px;
                  }
                }
                .detail-mating-list-lu {
                  color: #999999;
                  font-size: 14px;
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  .detail-about {
    .about-con {
      .img img {
        width: 100%;
        display: block;
        border-radius: 4px;
      }
      .txt {
        font-size: 16px;
        color: #666;
        line-height: 1.6;
      }
    }
  }
}

.housing_nav_time {
  color: #666666;
  font-size: 18px;
}
.housing_nav_name {
  color: #333333;
  font-size: 24px;
}
.housing_nav_ul {
  li {
    height: 28px;
    padding: 0 10px;
    border-radius: 3px;
    span {
      font-size: 14px;
    }
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    &:first-child {
      background: #e1ebff;
      span {
        color: #3273f6;
      }
    }
    &:nth-child(2) {
      background: linear-gradient(43deg, #3273f6, #689aff);
      span {
        color: #ffffff;
      }
    }
    &:nth-child(3) {
      span {
        color: #ff2121;
      }
      cursor: pointer;
      border: 1px solid #ff2121;
    }
  }
  .hei {
    color: #999999;
    border: 1px solid #cccccc;
  }
}
.housing_nav_coll {
  img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  span {
    color: #666666;
    font-size: 14px;
  }
  cursor: pointer;
}
.housing_nav_number {
  color: #666666;
  font-size: 18px;
}
.housing_head_left_video {
  width: 560px;
  height: 420px;
  position: relative;
  .swiper {
    height: 420px;
  }
  video {
    width: 100%;
    height: 100%;
    display: block;
  }
  .marking {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    img {
      width: 48px;
      height: 48px;
    }
  }
}
.housing_head_left_swiper {
  height: 90px;
  .swiper {
    width: 560px;
    height: 90px;
  }
}
.housing_head_left_ul {
  width: 560px;
  li {
    width: 130px;
    height: 56px;
    cursor: pointer;
    border-radius: 4px;
    img {
      width: 36px;
      height: 36px;
      margin-right: 10px;
    }
    span {
      color: #333333;
      font-size: 16px;
    }
    &:first-child {
      background: #e1ebff;
    }
    &:nth-child(2) {
      background: #ffeee1;
    }
    &:nth-child(3) {
      background: #fff7d7;
    }
    &:last-child {
      background: #ffd1cd;
    }
  }
}
.housing_head {
  align-items: flex-start;
}
.housing_head_right {
  width: 600px;
  padding: 32px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  .housing_head_price_txt {
    span {
      flex-shrink: 0;
      &:first-child {
        color: #666666;
        font-size: 16px;
        margin-right: 15px;
      }
      &:last-child {
        color: #ff2121;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .housing_head_lou_number {
    span {
      color: #999999;
      font-size: 16px;
      &:last-child {
        color: #333333;
      }
    }
  }
  .housing_head_lou_look {
    color: #005aef;
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
  }
  .housing_head_tag {
    li {
      color: #999999;
      font-size: 14px;
      height: 28px;
      padding: 0 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      background: #f5f5f5;
      border-radius: 3px;
    }
  }
  .housing_head_area {
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      span {
        &:first-child {
          color: #333333;
          font-size: 22px;
        }
        &:last-child {
          color: #666666;
          font-size: 16px;
          margin-top: 10px;
        }
      }
    }
  }
  .housing_head_time {
    padding: 20px;
    background: #e1ebff;
    border-radius: 4px;
    li {
      width: 50%;
      span {
        color: #666666;
        font-size: 16px;
        &:last-child {
          color: #333333;
        }
      }
      &:nth-child(3),
      &:first-child {
        margin-top: 8px;
      }
    }
  }
  .housing_head_footer {
    background: none;
    padding: 20px 0 20px 0;
    border-top: 1px solid #ebebeb;
    li {
      &:nth-child(3),
      &:first-child {
        margin-top: 0px;
      }
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        &:first-child {
          color: #999999;
        }
      }
      &:last-child,
      &:nth-last-child(2) {
        width: 100%;
      }
    }
  }
  .housing_head_img_left {
    img {
      width: 48px;
      height: 48px;
      margin-right: 10px;
      border-radius: 50%;
    }
    span {
      color: #333333;
      font-size: 16px;
    }
  }
  .housing_head_img_btn {
    width: 384px;
    height: 48px;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    background: #3273f6;
    border-radius: 4px;
  }
}
.color_blue {
  /deep/ .el-breadcrumb__inner {
    color: #3273f6;
  }
}
</style>
